import { FormattedMessage, useIntl } from 'gatsby-plugin-intl';

import Analytics from '../components/Analytics';
import BlueCircle from '../components/BlueCircle';
import Button from '../components/Button';
import Intro from "../components/Intro";
import Layout from "../components/Layout";
import LeftGolden from '../assets/svg/left-golden.svg';
import { Link } from 'gatsby';
import PinkCircle from '../components/PinkCircle';
import { PropTypes } from 'prop-types';
import React from "react";
import RightGreen from '../assets/svg/right-green.svg';
import SEO from "../components/Meta";
import Social from '../components/Social';
import Video from '../components/Video';

export const ROUTES = {
  HOME: '/',
  STEPS: '/deelnemen',
  PRIVACY_POLICY: '/privacy-policy',
  TIPS: '/tips',
  UPLOAD: '/uploaden',
  THANKS: '/bedankt',
}

const CircleListItem = ({ children }) => (
  <li className="sm:w-1/2">
    <div className="flex flex-col items-center p-2 mb-12 text-center">
      { children}
    </div>
  </li>
)

function IndexPage() {
  const intl = useIntl();

  return (
    <Layout>
      <SEO
        title={intl.formatMessage({id: "title_home", defaultMessage: "Zing mee met Het Warmste Koor van Radio 2."})}
        description={intl.formatMessage({
          id: "description_home",
          defaultMessage: "En bedank diegene die voor jou dit jaar het verschil heeft gemaakt."})}
        keywords={[`dww`, `de warmste week`, `het warmste koor`, `help`, `zingen`]}
      />

      <Analytics
        title="Home"
      />

      <Intro title={intl.formatMessage({id: "home_title", defaultMessage: "Wie maakte voor jou dit jaar het verschil?"})} showTitle={true}>
        <FormattedMessage
            id="home_description"
            defaultMessage="Bedank hem of haar uit volle borst en zing mee met Het Warmste Koor.
            Misschien verschijn je zelfs in de officiële bedankvideo van Radio 2.
            Britt en David tonen hoe het werkt:" />
      </Intro>

      <section className="relative mb-section">
        <div className="container">
          <div className="flex flex-col items-center -mx-16 sm:mx-0">
              <Video url="https://youtu.be/jnQoFBaArX4" thumb="tutorial.jpg" />

              <div className="px-16 text-center mt-section">
                <Button to={ROUTES.STEPS}>
                  <FormattedMessage
                    id="links_sing_along"
                    defaultMessage="Zing mee" />
                </Button>

                <Link to={ROUTES.UPLOAD} className="no-underline">
                  <p className="mb-1 mt-section">
                    <FormattedMessage
                        id="home_already_recorded"
                        defaultMessage="Heb je al een video opgenomen?" />
                  </p>

                  <strong className="inline-block underline">
                      <FormattedMessage
                        id="links_upload"
                        defaultMessage="Laad je film op!" />
                  </strong>
                </Link>
              </div>
          </div>
        </div>

        <RightGreen className="absolute bottom-0 right-0 hidden w-32 h-auto mb-48 sm:block xl:w-20" aria-hidden="true" />
        <LeftGolden className="absolute bottom-0 left-0 w-24 h-auto -ml-16 sm:-ml-12 sm:w-32 lg:-ml-4 xl:ml-0 lg:-mb-8 xl:-mb-12" aria-hidden="true" />
      </section>

      <div>
        <section className="container">
          <div className="flex flex-wrap lg:-mx-4">
            <div className="mb-12 lg:w-1/2 lg:px-4">
              <h2 className="h1">
                <FormattedMessage
                  id="home_song_title"
                  defaultMessage="Wat ga je zingen?" />
              </h2>

              <p className="mb-8">
                <FormattedMessage
                id="home_song_description"
                defaultMessage="Speciaal voor De Warmste Week maakte Noordkaap een nieuwe versie van hun meezinger ‘Ik Hou Van U’. Bekijk alvast de karaoke-video:" />
              </p>

              <Video url="https://youtu.be/FfIz0NMekik" thumb="karaoke.jpg" buttonColor="text-green" />
            </div>

            <div className="mb-12 lg:w-1/2 lg:px-4">
              <h2 className="h1">
                <FormattedMessage
                  id="home_devices_title"
                  defaultMessage="Wat heb je nodig?" />
              </h2>

              <p className="mb-8">
                <FormattedMessage
                  id="home_devices_description"
                  defaultMessage="Een GSM of tablet met camera om je optreden te filmen én een extra toestel met hoofdtelefoon of oortjes om de karaoke-video op af te spelen." />
              </p>

              <ul className="flex flex-wrap justify-around -mx-2">
                <CircleListItem>
                  <BlueCircle />

                  <strong className="block mt-4 text-sm">
                    <FormattedMessage
                      id="home_devices_record_description"
                      defaultMessage="Met dit toestel neem je je video op." />
                  </strong>
                </CircleListItem>

                <CircleListItem>
                  <PinkCircle />

                  <strong className="block mt-4 text-sm">
                    <FormattedMessage
                        id="home_devices_listen_description"
                        defaultMessage="Dit toestel gebruik je om de karaoke-video te beluisteren terwijl je meezingt." />
                  </strong>
                </CircleListItem>
              </ul>
            </div>
          </div>
        </section>
      </div>

      <section className="container text-center mb-section">
        <p className="max-w-3xl mx-auto mb-section">
          <FormattedMessage
              id="home_tips_ready"
              defaultMessage="Klaar om mee te zingen?" />
          {' '}
          <Link to={ROUTES.TIPS}>
            <FormattedMessage
                id="home_tips_see"
                defaultMessage="Maak van jouw video een succesnummer met onze tips." />
          </Link>
          {' '}
          <FormattedMessage
              id="home_tips_go"
              defaultMessage="Heb je het nummer onder de knie en alles bij de hand?" />
        </p>

        <Button to={ROUTES.STEPS}>
          <FormattedMessage
            id="links_sing_along"
            defaultMessage="Zing mee" />
        </Button>
      </section>

      <Social/>
    </Layout>
  );
}

CircleListItem.propTypes = {
  children: PropTypes.node
}

export default IndexPage;
